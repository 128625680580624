.verticalCardImage img {
  -webkit-transition: width 2s;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 0.9;
  object-fit: cover;
}

.verticalCardImage:hover {
  cursor: pointer;
}

.verticalCardImage:hover img {
  transition: all 1000ms;
  width: 70%;
  opacity: 0.4;
}

.verticalCardImage:hover .textBox {
  transition: all 1000ms;
  color: #d5d268;
}

.verticalCardImage {
  position: relative;
  height: 500px;
  background-color: black;
  display: flex;
  margin: 3px;
}

.verticalCardImage .textBox {
  padding: 10px;
  position: absolute;
  display: block;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  bottom: 0;
  width: 100%;
}

.verticalCardImage .textBox h2,
.verticalCardImage .textBox p {
  width: 100%;
  flex-direction: row;
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  border: 1px solid gray;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.modal .header {
  background-color: black;
  width: 100%;
  height: 5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .header p {
  width: 100%;
  margin-left: 20px;
}

.modal span {
  color: #fff;
  font-size: 3rem;
  padding: 10px;
  float: right;
  cursor: pointer;
}

.modal .wrapper {
  height: 90%;
  width: 100%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal img {
  max-width: 800px;
  max-height: 90%;
}

.modal .btn-prev,
.modal .btn-next {
  width: 60px;
  height: 60px;
  background-color: #fff;
  color: #000;
  font-size: 42px;
  cursor: pointer;
  vertical-align: middle;
  display: inline-table;
}

.modal .btn-prev:hover,
.modal .btn-next:hover {
  background-color: #464646;
  color: #fff;
}

.modal .btn-prev {
  position: absolute;
  top: 45%;
  left: 0;
}

.modal .btn-next {
  position: absolute;
  top: 45%;
  right: 0;
}

h1.secondaryTitle {
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid;
  margin: 10px;
  width: 100%;
  color: #4579a3;
}

ul.primary-list {
  padding: 15px 40px;
  list-style: none;
}

.primary-list b{
  font-weight: 600;
}

ul.sublist li {
  font-weight: 100;
  list-style: none;
  padding-left: 10px;
}

.textBox{
  padding-right: 20px;
}

.text-right{
  float:right
}

.slider-card-image{
  width: 100%;
  height: 220px;
  background-color: rgb(240 240 240 / 80%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.slider-card{
  margin: 0px 10px !important;
}

.square-img{
  height: 100%;
  max-height: 400px;
}

.slider2 img{
  width: 25%;
  height: auto;
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 10px;
  padding: 10px;
}
#main-slider-container {
  width: 100% !important;
}

.lfgrwr{
  max-width: 100% !important;
}
.custYS {
  max-width: 90% !important;
}

.tab{
  padding: 5px 25px 10px 25px;
  border: 1px solid #b9ccd1;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  background: #b9ccd1;
}