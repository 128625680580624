* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Muli, "PT Sans", sans-serif;
}

@font-face {
  font-family: Muli;
  src: url(assets/fonts/Muli.ttf);
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.card {
  background-color: #fff;
  color: #000;
  padding: 15px;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%);
  margin: 10px;
  border-radius: 2px;
  font-size: 1rem;
  display: -webkit-flex;
}

.card:hover {
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
  transition: all 500ms;
  /* background: #091554; */
  /* color: #fff; */
}

.container {
  margin: 0 auto;
  position: relative;
  padding: 25px;
  /* margin-left: 100px; */
}

.row {
  display: -webkit-flex;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

.col {
  float: left;
  margin: 10px;
  min-height: 100px;
}

.withoutmargin {
  margin: 0 auto;
}

.col-2 {
  width: 20%;
  margin: 0 auto;
}

.col-3 {
  width: 33.3%;
  margin: 0 auto;
}

.col-4 {
  width: 25%;
  margin: 0 auto;
}

.col-5 {
  width: 50%;
  margin: 0 auto;
}

.col-4-reverse {
  width: 75%;
  margin: 0 auto;
}

.col-3-reverse {
  width: 66.6%;
  margin: 0 auto;
}

.col-2-reverse {
  width: 80%;
  margin: 0 auto;
}

.col-10 {
  width: 100%;
  margin: 0 auto;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.primaryTitle {
  font-size: 40px;
  line-height: 64px;
  text-transform: uppercase;
  float: left;
  margin-bottom: 30px;
  text-align: left;
  width: 100%;
  color: #000;
  font-weight: 100;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.noBullets {
  list-style: none;
}

.list {
  width: 100%;
}

.list li {
  border: 1px solid #1a1818;
  width: 100%;
  display: flex;
  background: #fff;
}

.list span {
  padding: 5px;
  min-height: 1rem;
}

.bgBlack {
  background: #1a1818;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .col-2,
  .col-2-reverse,
  .col-3,
  .col-3-reverse,
  .col-4,
  .col-4-reverse,
  .col-5,
  .col-10 {
    width: 100% !important;
    margin: 0 auto;
  }
  .slider2 img {
    width: 100% !important;
    margin: 0px !important;
  }
  .custYS {
    max-width: 90% !important;
  }
  #next,#prev{display: none;}
}

@media screen and (max-width: 959px) {
  .row {
    display: inline;
  }
  .container {
    margin-left: 0px;
  }
  .col-4,
  .col-4-reverse,
  .col-3,
  .col-3-reverse,
  .col-2,
  .col-2-reverse {
    width: 50%;
    margin: 0 auto;
  }
  .col-10 {
    width: 100%;
    margin: 0 auto;
  }
}

.ra {
  color: #2bb2ff;
}
.mi {
  color: #ff5b5c;
}
.co {
  color: #d9d9d9;
}
